import { loggedInTeamsCookie } from './Cookies';

type Teams = string[];

function getAll() {
  return (loggedInTeamsCookie.get() as Teams) || [];
}

function save(teams: Teams) {
  loggedInTeamsCookie.set(teams);

  return teams;
}

function add(id: string) {
  const teams = getAll();
  if (!teams.includes(id)) {
    teams.push(id);
  }

  return save(teams);
}

function remove(id: string) {
  const newTeams = getAll().filter((savedId) => savedId !== id);

  return save(newTeams);
}

const LoggedInTeams = {
  getAll,
  add,
  remove,
};

export default LoggedInTeams;
